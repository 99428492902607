.message {
  margin-bottom: 10px;
  padding: 8px 15px;
  border-radius: 20px;
  max-width: 70%; /* Set maximum width for the messages */
  word-wrap: break-word; /* Wrap long words to prevent overflow */
}

.user-message {
  background-color: #4b127d;
  color: white;
  float: right; /* Float user messages to the right */
  clear: both; /* Clear any previous floats */
  text-align: left;
}

.assistant-message,
.assistant-message.otherdata.unknown {
  background-color: #8d0b93;
  color: #ffffff;
  text-align: left; /* Align assistant messages to the left */
  float: left; /* Float assistant messages to the left */
  clear: both; /* Clear any previous floats */
}

.assistant-message.otherdata {
  background-color: unset;
  color: #4b127d;
  max-width: 90% !important;
}

.assistant-message.otherdata.Pie_Chart {
  border: 1px solid #4b127d;
}

.message-input button:disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.assistant-selection {
  margin-bottom: 10px;
  padding: 0 10px;
}

.chat-window {
  flex: 1;
  overflow-y: auto; /* Ensure that chat window is scrollable */
  padding: 10px;
  min-height: 200px;
}

.message-input {
  display: flex;
  align-items: center;
  position: absolute; /* Position the input field absolutely */
  bottom: 0; /* Align the input field to the bottom */
  left: 0; /* Align the input field to the left */
  width: calc(100% - 4px); /* Make the input field fill the entire width */
  padding-bottom: 10px;
  background: #fff;
}

.message-input input {
  flex: 1;
  margin-right: 10px;
  border: 1px solid #ccc; /* Add a border */
  border-radius: 4px;
  width: calc(100% - 80px); /* Adjust the width of the input field */
  padding: 8px;
  height: 38px;
}

.message-input input {
  width: calc(100% - 80px); /* Adjust the width of the input field */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  height: 38px;
}

.dots {
  width: 56px;
  height: 26.9px;
  background: radial-gradient(circle closest-side, #4b127d 90%, transparent),
    radial-gradient(circle closest-side, #4b127d 90%, transparent),
    radial-gradient(circle closest-side, #4b127d 90%, transparent);
  background-size: calc(100% / 3) 13.4px;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 75px;
  animation: dots-7ar3yq 1s infinite linear;
}

@keyframes dots-7ar3yq {
  20% {
    background-position:
      0% 0%,
      50% 50%,
      100% 50%;
  }

  40% {
    background-position:
      0% 100%,
      50% 0%,
      100% 50%;
  }

  60% {
    background-position:
      0% 50%,
      50% 100%,
      100% 0%;
  }

  80% {
    background-position:
      0% 50%,
      50% 50%,
      100% 100%;
  }
}

.loader-div {
  min-height: 20px;
}

.chat-window::-webkit-scrollbar {
  width: 20px;
}

.chat-window::-webkit-scrollbar-track {
  background-color: transparent;
}

.chat-window::-webkit-scrollbar-thumb {
  background-color: #ececec;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.chat-window::-webkit-scrollbar-thumb:hover {
  background-color: #c4c5c6;
}

.message.assistant-message div p {
  margin: 0px !important;
  font-size: 14px;
}

.message.user-message div p {
  margin: 0px !important;
  font-size: 14px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: calc(90vh - 65px);
  padding: 20px;
}

.bot-chat-container {
  display: flex;
  flex-direction: column;
  height: calc(106vh - 65px);
  padding: 2px;
}

.chat-box {
  flex: 1; /* Allow the chat-box to expand and fill the remaining space */
  display: flex;
  flex-direction: column;
  position: relative; /* Position relative for absolute positioning of input field */
}

.chat-window {
  flex: 1; /* Allow the chat-window to expand and fill the remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px;
  border: none !important;
  margin-bottom: 50px;
}

.prompt-cards {
  position: absolute;
  bottom: 100px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow wrapping of prompt cards */
  background-color: #fff; /* Adjust background color as needed */
  padding: 10px;
}

.prompt-card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin: 5px; /* Add margin to separate prompt cards */
  flex: 0 0 calc(50% - 10px); /* Set flex basis to 50% minus margin for two cards per row */
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.uploaded-files {
  display: flex;
  flex-wrap: wrap;
}

.assistant-message h3 {
  font-size: 24px;
  margin: 0px !important;
}

.k-switch-on .k-switch-thumb {
  background-color: #521280;
  border-color: #521280;
}

.k-switch.k-switch-off .k-switch-thumb::before,
.k-switch.k-switch-on .k-switch-thumb::before {
  background-color: unset !important;
}

.file-input-label {
  margin: 4px;
  padding: 8px;
  border-radius: 4px;
}

.streaming-response-switch{
  .k-switch-md .k-switch-thumb {
    width: 20px !important;
    height: 20px !important;
  }
  .k-switch-md .k-switch-track {
    width: 38px !important;
    height: 16px !important;
  }
}
