.code-content {
  max-height: 400px; /* Adjust the max height as needed */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Custom scrollbar */
.code-content::-webkit-scrollbar,
pre::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.code-content::-webkit-scrollbar-thumb,
pre::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 4px;
}

.code-content::-webkit-scrollbar-track,
pre::-webkit-scrollbar-track {
  background-color: #ddd;
}

.k-pre,
pre,
.k-code,
code {
  color: unset;
  background-color: #8d0b93;
  padding: 0 20px;
  border-radius: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  line-height: unset !important;
}

.code-block {
  position: relative;
  margin: 1em 0;
  border-radius: 5px;
  overflow: hidden;
}

.code-block-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #333;
  padding: 0.5em;
}

.copy-button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.2em;
}

.copy-status {
  display: block;
  color: white;
}
