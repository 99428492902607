.smart-custom-bot {
  .bot-message-input {
    padding: 0 10px 10px 10px;
  }

  .prompt-cards {
    bottom: 50px;
  }
}

.bot-chat-window {
  flex: 1; /* Allow the chat-window to expand and fill the remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 11px 11px 25px 11px;
  border: none !important;
  margin-bottom: 20px;
}

.bot-message-input {
  position: fixed; /* Position the input field absolutely */
  bottom: 0; /* Align the input field to the bottom */
  left: 0; /* Align the input field to the left */
  width: calc(100% - -18px); /* Make the input field fill the entire width */
  background: #fff;
  display: flex;
  align-items: center;
}

.bot-message-input input {
  width: calc(100% - 80px); /* Adjust the width of the input field */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  height: 38px;
}

.selected-assistant {
  display: inline-block;
  padding: 1px 5px;
  font-size: 12px;
  color: #521280;
}

.assistant-name {
  font-size: 12px;
  font-weight: bold;
}

.custom-select {
  color: #521280;
  border: 0;
  font-weight: bold;
  border-bottom: 1px solid #521280;
  width: 72px;
}

/* Custom scrollbar styles */
.bot-chat-window::-webkit-scrollbar {
  width: 6px;
}

.bot-chat-window::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.bot-chat-window::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.bot-chat-window::-webkit-scrollbar-track {
  background-color: #f9f9f9;
}

.custom-dots {
  width: 56px;
  height: 20px;
  background: radial-gradient(circle closest-side, #007ab4 90%, transparent),
    radial-gradient(circle closest-side, #007ab4 90%, transparent),
    radial-gradient(circle closest-side, #007ab4 90%, transparent);
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 52px;
  animation: dots-7ar3yq 1s infinite linear;
}

.assistant-name-custom {
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.custom-bot-header {
  background-image: linear-gradient(
    -225deg,
    #ff057c 0%,
    #8d0b93 50%,
    #321575 100%
  );
  border-radius: 4px;
  padding: 12px;

  .assistant-name-custom {
    padding: 0 !important;
    color: white;
  }

  p {
    margin: 0;
  }
}

.blue-yonder-theme {
  .assistant-message {
    background-color: #007ab4;
  }

  .assistant-message.otherdata {
    background-color: white;
    color: #404040;
  }

  .assistant-message.otherdata.unknown {
    background-color: #007ab4;
    color: white;
  }

  .user-message {
    background-color: rgb(61 61 61);
  }

  .ai-button-primary {
    background-image: linear-gradient(
      135deg,
      #007ab4 0%,
      #007ab4 50%,
      #007ab4 100%
    );

    &:hover {
      background-image: linear-gradient(
        135deg,
        #404040 0%,
        #404040 50%,
        #404040 100%
      );
    }
  }

  .delete-button {
    color: #007ab4;
  }

  .delete-button.reset-button {
    color: #404040;
  }

  .k-loading-image {
    color: #007ab4;
  }

  .custom-bot-header {
    background-image: linear-gradient(
      135deg,
      #007ab4 0%,
      #007ab4 50%,
      #007ab4 100%
    );
  }
}
