.dashboard-container {
  padding: 20px;
}

.dashboard-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard-card {
  width: calc(50% - 20px);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #47137c !important;
  color: white !important;
}

.dashboard-content .card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dashboard-icon.fas {
  font-size: 80px;
}

.card-content h3 {
  margin: 0;
  font-size: 18px;
  color: #fff;
}

.count {
  font-size: 30px;
  text-align: right;
  align-self: flex-end; /* Align to bottom */
  color: #fff;
}

/* Media Query for Small Screens */
@media screen and (max-width: 768px) {
  .dashboard-card {
    width: calc(100% - 20px); /* Make cards full width */
  }
}