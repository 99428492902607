.grid-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white;
  padding: 30px;
}

.chart-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white;
  padding: 30px;
}

.image-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white;
  padding: 30px;

  .chart-image {
    width: unset;
  }
}

.chat-ex-buttons {
  padding: 5px 10px !important;
}

.grid-wrapper.chat {
  padding: 0 !important;
}

.markdown-renderer ol {
  margin-top: 0;
}

.image-wrapper {
  max-width: 100%; /* Limit the width of the container */
  max-height: 80%; /* Adjust the height to your preference */
  overflow: auto; /* Enable scrolling when content overflows */
}

.chart-image {
  width: 550px;
  height: auto;
}

.image-wrapper::-webkit-scrollbar {
  width: 16px;
}

.image-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.image-wrapper::-webkit-scrollbar-thumb {
  background-color: #c4c5c6;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.image-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #8f8f8f;
}

.data-grid {
  height: 300px;
}
