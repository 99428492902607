.custom-card {
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px; /* Optional: Adjust margin for spacing between cards */
  margin-bottom: 10px; /* Optional: Adjust margin for spacing between cards */
}

.card-body {
  display: flex;
  align-items: center;
}

.file-name {
  margin-right: 10px; /* Optional: Adjust margin between file name and delete button */
}

.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: border-color 0.3s ease;
  height: 100px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.dropzone:hover {
  border-color: #999;
}

.dropzone p {
  color: #f55f54;
}

.k-text-area {
  width: 100%;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-width: 1px 0;
  border-top-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
  background-color: whitesmoke;
}

.ai-slider .k-card-title {
  color: #4b127d !important;
}
